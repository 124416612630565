import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Privacy = () => (
  <div className={`bg-gray-700`}>
  <Layout>
    <SEO title="Privacy Policy" />
      <section className={`container px-5 mx-auto mb-20`}>
        <div className={`flex flex-wrap text-white`}>
        {/* <!-- intro-content --> */}
          <div className={`w-full sm:mx-16 `}>
            <h1 className={`mb-2 font-hairline  md:border-b-0 lg:border-b-2 text-2xl md:text-3xl lg:text-4xl sm:text-center sm:leading-none sm:pb-3 xl:leading-tight lg:text-left`}>Website Privacy Statement</h1>
            <div className={``}>
              <p>This statement confirms how I, Andrew Newton of bathcounsellingservices.co.uk use and protect any information that you give to me when you use this service.</p>

              <p>I am committed to safeguarding and protecting your information. If I ask you for any information then it will only be used according to this privacy statement.</p>

              <p>I may change this policy from time to time in line with legislation and I advise that you check this page from time to time to be aware of any changes. This policy is effective from 1st February 2020.</p>

              <p>By continuing to use my website bathcounsellingservices.co.uk you are accepting and consenting to the practices described in this policy and agreeing to my use of cookies described below.</p>

              <p>Any changes to this policy will be on this page and if relevant will be notified you by email.</p>

              <p>For the purpose of Data Protection Act 1998 and General Data Protection Regulation 2016 Andrew Newton is the data controller of address bathcounsellingservices.co.uk</p>


              <h3>Information collected</h3>
              <p>Like most websites my website uses Google Analytics (GA). This data is used to look at the number of people using the site and how they find it and how they journey through the website. It also records your IP address which can personally identify you. Google do not give me access to this.</p>

              <p>You may also give me information such as your name, address and telephone number by completing the form on my site or by contacting me by telephone or email or by commenting on a blog.</p>

              <p>Where necessary to act in your best interests I may need to process information, that is sensitive in nature such as health information. In some circumstances I may need to share this information with a third party such as a GP. I would advise that you do not share personal sensitive information on my website but if you choose to do so, then you will be allowing me to process it as part of my services to you.</p>


              <h3>Cookies</h3>
              <p>My site does use cookies in order to distinguish you from other users. You are able to disable cookies which will prevent your visit being tracked. Some cookies are essential for the website to work properly. I would advise you to review and if necessary disable cookies on your internet browser regularly. Please see http://www.youronlinechoices.com/uk/ to provide you with information about cookies and http://www.aboutcookies.org/ for advice about deleting and managing cookies.</p>


              <h3>Blog posts</h3>
              <p>If you were to comment on a blog then your name and email address will be saved to the website’s database, with your IP address. This is not passed on to a 3rd party. Your comment will remain on the website until I remove the comment or the blog post. Please email me at andrew@bathcounsellingservices.co.uk if you wish for the comment to be removed, using the email that you commented with.</p>

              <p>If you are under 16 then you must obtain parental consent before posting a comment.</p>

              <p>lease do not leave personally identifiable information on the comment field of any blog post.</p>


              <h3>How I store information</h3>
              <p>If you contact me via my ‘Contact Me’ form, no data will be stored by the website or passed to any third party. All data will be transferred and stored within the European Economic Area.</p>

              <p>How you transmit data onto my site is at your risk and I cannot guarantee the safety of this. Once I have received your data then I have procedures and security to ensure I do my best to safeguard your information and prevent unauthorised access.</p>

              <p>My website is protected by SSL so that the email content is encrypted before being sent over the internet.</p>


              <h3>How I use information</h3>
              <p>I use information in order</p>

              <p>To carry out contracts between yourself and me and provide you with the services that you request from me</p>

              <p>To administer my website, data analysis, statistical and survey purposes, testing.</p>

              <p>To enable improvements to the website</p>
              <p>To keep the website safe and secure</p>
              <p>To keep records such as client hours</p>
              <p>To run and maintain my business ie for financial records</p>
              <p>To let you know of changes to my service</p>


              <h3>Storage</h3>
              <p>I will store information for no longer than 7 years. Some information such as texts may be deleted immediately. My computer is password protected to ensure the safety and protection of your information. I provide detailed information regarding this on request.</p>

              <p>I keep brief notes recording the themes of our sessions. These are stored in a locked filing cabinet and destroyed 7 years after your counselling sessions end unless agreed otherwise. Your contact and apt details will be kept for 7 years and then destroyed unless agreed otherwise. Any emails, texts, whatsapp messages or other communications will be deleted immediately after receipt unless they form part of your notes or are needed for income tax purposes in which case they will be kept for 7 years as above. Your telephone number will be stored on my mobile phone under your initials or first name only until your sessions end at which point it will be deleted. If I change telephones then your details will be deleted on the old phone. All electronic information will be securely deleted by a specialist software programme.</p>


              <h3>Access to information</h3>
              <p>You can request access to the personal information that I hold on you and except in limited circumstances when I am not permitted to do so for legal reasons, I will provide this information to you within one month.</p>

              <p>If you feel that any information that I hold about you is inaccurate then you are able to ask me to update your information. If you want me to delete your information then please do so in writing and I will endeavour to do so unless I need to keep it for legal or internal business purposes.</p>


              <h3>Contact</h3>
              <p>If you wish to contact me with questions, concerns, complaints or request for your information then please contact me at:</p>
              <p>
              Andrew Newton <br />
              26 Upper Borough Walls<br />
              Bath, BA1 1RH<br />
              andrew@bathcounsellingservices.co.uk<br />
              0783 499 1034
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
    </div>
)

export default Privacy
